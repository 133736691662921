export enum Route {
    Agent = "/agents/:agentId",
    Agents = "/agents",
    AgentsSetup = "/agents/:agentId/setup-user",
    AgentsCounts = "/agents/counts",
    AgentStates = "/agents/states",
    AnomalyEvents = "/anomalies/:anomalyId/anomaly-events",
    Anomalies = "/anomalies",
    AnomaliesTrendingPhrasesVariances = "/anomalies/:anomalyId/trending-phrases/variances",
    AnomalyTagVariances = "/anomalies/:anomalyId/tag-variances",
    Anomaly = "/anomalies/:anomalyId",
    AuthenticationParameters = "/authentication-parameters",
    Client = "/clients/:clientId",
    InteractionDetails = "/interactions/:interactionId/details",
    InteractionMetrics = "/interactions/:interactionId/metrics",
    InteractionMetricsAvailable = "/interaction-metrics/available",
    InteractionMetricsDistributions = "/interaction-metrics/distribution",
    InteractionMetricsSummaries = "/interaction-metrics/summaries",
    InteractionMessages = "/interactions/:interactionId/messages",
    InteractionMessagesWritingIssues = "/interactions/:interactionId/messages/writing-issues",
    InteractionTags = "/interactions/:interactionId/tags",
    InteractionVariablesValues = "/interactions/:interactionId/variables/values",
    Interactions = "/interactions",
    InteractionsAggregates = "/interactions/aggregates",
    InteractionAudio = "/interactions/:interactionId/recordings/link",
    InteractionsCounts = "/interactions/counts",
    InteractionsMessage = "/interactions/messages/:interactionMessageId",
    InteractionsMessageTag = "/interactions/messages/:interactionMessageId/tags/:tagId",
    InteractionsMessageTags = "/interactions/:interactionId/messages/:interactionMessageId/tags",
    InteractionsMessages = "/interactions/messages",
    InteractionsMessagesCounts = "/interactions/messages/counts",
    InteractionMessageSentiments = "/interactions/:interactionId/messages/:messageId/sentiments",
    InteractionMessageSentimentUpdates = "/interactions/:interactionId/messages/:messageId/sentiments/updates",
    InteractionSummary = "/interactions/:interactionId/summaries",
    InteractionAgents = "/interactions/:interactionId/agents",
    Languages = "/languages",
    MetabaseDashboards = "/metabase-dashboards",
    MetabaseTokens = "/metabase-dashboards/:metabaseDashboardId/tokens",
    MetricModelOutputs = "/models/:modelId/outputs",
    Model = "/models/:modelId",
    Models = "/models",
    SubscribedUsers = "/tags/:tagId/subscriptions",
    UnsubscribeUsers = "/tags/:tagId/subscriptions/:subscriptionId",
    UserSubscriptions = "/users/:userId/models",
    ParameterGroup = "/parameter-groups/:parameterGroupId",
    ParameterGroups = "/parameter-groups",
    Permissions = "/permissions",
    PhraseTrigger = "/phrase-triggers/:phraseTriggerId",
    PhraseTriggers = "/phrase-triggers",
    PhraseTriggersSamples = "/phrase-triggers/samples",
    PhraseTriggersResult = "/phrase-triggers/results/:phraseTriggerResultId",
    PhraseTriggersResults = "/phrase-triggers/results",
    Role = "/roles/:roleId",
    Roles = "/roles",
    RolePermission = "/roles/:roleId/permissions/:permissionId",
    RolePermissions = "/roles/:roleId/permissions",
    SpellingExemption = "/spelling-exemptions/:spellingExemptionId",
    SpellingExemptions = "/spelling-exemptions",
    Tag = "/tags/:tagId",
    Tags = "/tags",
    TagsAgents = "/tags/:tagId/agents",
    TagGroup = "/tag-groups/:tagGroupId",
    TagGroups = "/tag-groups",
    TagGroupInteractionsVariances = "/tag-groups/:tagGroupId/interactions/variances",
    Team = "/teams/:teamId",
    Teams = "/teams",
    TeamsBrands = "/teams/brands",
    TimeZones = "/time-zones",
    Tokens = "/tokens",
    TokensRefresh = "/tokens/refresh",
    Translations = "/translations",
    User = "/users/:userId",
    UserInvite = "/users/:userId/invites",
    UserAgents = "/users/:userId/agents",
    UserSubscribe = "/users/:userId/models",
    Users = "/users",
    UsersMe = "/users/me",
    UsersClients = "/users/me/clients",
    WritingIssues = "/writing-issues",
    WritingIssuesCounts = "/writing-issues/counts",
    InteractionsAnalytics = "/interactions/analytics",
    InteractionsAnalyticsMetrics = "/interactions/analytics/metrics",
    Scorecards = "/scorecards",
    ScorecardConfiguration = "/scorecards/:scorecardId",
    ScorecardEvaluations = "/scorecard-evaluations",
    ScorecardEvaluation = "/scorecard-evaluations/:scorecardEvaluationId",
    ScorecardEvaluationExists = "/scorecard-evaluations/exists",
    ScorecardEvaluationNotifications = "/scorecard-evaluations/:scorecardEvaluationId/notifications",
}
